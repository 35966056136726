var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("verify-screening-notices", {
        attrs: { authorizations: _vm.applicant.authorizations }
      }),
      _c("verify-screening-form", {
        attrs: {
          profile: _vm.applicant.profile,
          loading: _vm.dataLoading,
          disabled: _vm.dataLoading,
          clearable: _vm.applicant.sections.clearable,
          "hide-input": _vm.applicant.authorizations.sb_484_verified
        },
        on: { search: _vm.handleSearch, clear: _vm.handleClear }
      }),
      _vm.dataLoading
        ? _c("v-progress-linear", {
            staticClass: "mt-2",
            attrs: { indeterminate: "", color: "primary" }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }