<template>
    <div>
        <verify-screening-notices
            :authorizations="applicant.authorizations"
        ></verify-screening-notices>
        <verify-screening-form
            :profile="applicant.profile"
            @search="handleSearch"
            @clear="handleClear"
            :loading="dataLoading"
            :disabled="dataLoading"
            :clearable="applicant.sections.clearable"
            :hide-input="applicant.authorizations.sb_484_verified"
        ></verify-screening-form>
        <v-progress-linear
            class="mt-2"
            v-if="dataLoading"
            indeterminate
            color="primary"
        ></v-progress-linear>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        VerifyScreeningForm: () => import("./VerifyScreeningForm"),
        VerifyScreeningNotices: () => import("./VerifyScreeningNotices"),
    },
    props: {
        applicant: {
            type: Object,
            required: true,
        },
        application: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters("Applications", ["dataLoading"]),
    },
    data() {
        return {};
    },
    watch: {
        applicant: {
            immediate: true,
            deep: true,
            handler(applicant) {
                if (applicant.authorizations.sb_484_verified) {
                    // Completed Verification
                    this.$emit("update", {
                        complete: true,
                        data: applicant.authorizations,
                    });
                }
            },
        },
    },
    methods: {
        ...mapActions("Applications", ["searchHistory"]),
        handleSearch(profileData, method) {
            const payload = Object.assign(
                profileData,
                { method },
                { company_id: this.application.company.id },
                { order_id: this.applicant.order_id }
            );
            this.searchHistory(payload);
        },
        handleClear(profileData) {
            this.$emit("update", {
                applicant: {
                    authorizations: Object.assign(
                        this.applicant.authorizations,
                        {
                            sb_484_verified: false,
                            sb_484_passes: false,
                        }
                    ),
                    profile: Object.assign(this.applicant.profile, profileData),
                },
            });
        },
    },
};
</script>

<style scoped>
</style>